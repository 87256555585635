/** @jsx jsx */

import { Link } from "gatsby"
import { Box, jsx, Heading } from "theme-ui"

const Footer = () => (
  <Box
    sx={{
      fontSize: 1,
      py: 4,
      px: [4, 4, 5],
      backgroundColor: "#EBF1F5",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: ["column", "column", "row"],
        py: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderLeft: ["none", "none", "6px solid #CED9E0"],
          pl: [0, 0, 3],
        }}
      >
        <Heading as="h2" sx={{ m: 0, width: [180, 200], fontSize: [2] }}>
          <Link
            to="/"
            sx={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            The Computational Democracy Project
          </Link>
        </Heading>
        <Box
          sx={{
            fontFamily: "body",
            display: ["none", "none", "block"],
            maxWidth: 200,
          }}
        >
          © {new Date().getFullYear()} The Computational Democracy Project, a 501c3 nonprofit.
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexShrink: 0,
            flexDirection: "column",
            px: [0, 0, 4],
            py: [3, 3, 0],
          }}
        >
          <Heading as="h4" sx={{ mb: 3 }}>
            About
          </Heading>
          <Link to="/" sx={{ variant: "styles.footerLink", pr: 2 }}>
            Home
          </Link>
          <Link to="/about" sx={{ variant: "styles.footerLink", pr: 2 }}>
            About
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexShrink: 0,
            flexDirection: "column",
            px: [0, 0, 4],
            py: [3, 3, 0],
          }}
        >
          <Heading as="h4" sx={{ mb: 3 }}>
            Connect
          </Heading>
          <Link
            to="https://twitter.com/compdem"
            sx={{ variant: "styles.footerLink", pr: 2 }}
          >
            @compdem
          </Link>
          <Link to="/volunteering" sx={{ variant: "styles.footerLink", pr: 2 }}>
            Volunteering
          </Link>
          <Link to="/contact" sx={{ variant: "styles.footerLink", pr: 2 }}>
            Contact
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexShrink: 0,
            flexDirection: "column",
            px: [0, 0, 4],
            py: [3, 3, 0],
          }}
        >
          <Heading as="h4" sx={{ mb: 3 }}>
            Polis Knowledge Base
          </Heading>
          <Link
            to="/knowledge-base"
            sx={{ variant: "styles.footerLink", pr: 2 }}
          >
            Table of Contents
          </Link>
          <Link to="/Welcome" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              👋
            </span>
            Welcome!
          </Link>
          <Link to="/Usage" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              🔩
            </span>
            Usage
          </Link>
          <Link to="/Quickstart" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              🏎
            </span>
            Quickstart
          </Link>
          <Link to="/Identity" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              👩‍🎤
            </span>
            Identity
          </Link>
          <Link
            to="/Media-coverage"
            sx={{ variant: "styles.footerLink", pr: 2 }}
          >
            <span role="img" aria-label="emoji">
              🗞
            </span>
            Media coverage
          </Link>
          <Link to="/Case-studies" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              ⚗️
            </span>
            Case studies
          </Link>
          <Link to="/FAQ" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              📖
            </span>
            FAQ
          </Link>
          <Link to="/algorithms" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              👾
            </span>
            Algorithms
          </Link>
          <Link to="/Moderation" sx={{ variant: "styles.footerLink", pr: 2 }}>
            <span role="img" aria-label="emoji">
              👹
            </span>
            Moderation
          </Link>
        </Box>
        
        <Box
          sx={{
            fontFamily: "body",
            display: ["block", "block", "none"],
            maxWidth: 200,
          }}
        >
          © {new Date().getFullYear()} The Computational Democracy Project, a 501c3 nonprofit.
        </Box>
        <Box>
          <a rel="me" href="https://mastodon.social/@colinmegill"/>
        </Box>
      </Box>
    </Box>
  </Box>
)

export default Footer
