export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "EB Garamond, serif",
    heading: "EB Garamond, serif",
    headingAlt: "EB Garamond, serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.7,
    heading: 1.3,
  },
  text: {
    default: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      maxWidth: ["20em"],
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  colors: {
    text: "#202B33",
    background: "white",
    primary: "rgb(3, 169, 244)",
    secondary: "#30c",
    muted: "#f6f6f6",
    grayOne: "5C7080",
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    navlink: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    footerLink: {
      fontFamily: "body",
      fontSize: 1,
      color: "text",
      fontWeight: 300,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      mb: 3,
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      maxWidth: [600],
      my: [4],
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      maxWidth: [600],
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
      maxWidth: [600],
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
      maxWidth: [600],
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
      maxWidth: [600],
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
      maxWidth: [600],
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1],
      maxWidth: [600],
    },
    a: {
      color: "primary",
      fontWeight: 700,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    ul: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1],
      maxWidth: ["40em"],
    },
    li: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [1],
      maxWidth: ["40em"],
    },
  },
}
