/** @jsx jsx */
import { Link } from "gatsby"

import { Flex, Box, Heading, jsx } from "theme-ui"

const Header = () => (
  <header
    sx={{
      flex: `0 0 auto`,
      backgroundColor: "#EBF1F5",
    }}
  >
    <Flex
      sx={{
        marginBottom: `1.45rem`,
        margin: `0 auto`,
        px: [4, 5],
        py: 4,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Heading as="h2" sx={{ m: 0, width: [180, 200], fontSize: [2] }}>
          <Link
            to="/"
            sx={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            The Computational Democracy Project
          </Link>
        </Heading>
      </Box>
      <Box>
        <Flex>
          <Link
            to="/services"
            sx={{
              variant: "styles.footerLink",
              px: 2,
            }}
          >
            Services
          </Link>

          <Link
            to="/about"
            sx={{
              variant: "styles.footerLink",
              px: 2,
            }}
          >
            About
          </Link>
        </Flex>
      </Box>
    </Flex>
  </header>
)

export default Header
