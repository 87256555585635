/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "theme-ui"
import theme from "../theme/theme"
import SEO from "../components/seo"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <SEO title={data.site.siteMetadata.title} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `1.45rem auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          backgroundColor: "white",
          // display: `flex`,
          // flexDirection: `column`,
          // justifyContent: `flex-start`,
          width: `100%`,
          flex: `1 1 100%`,
        }}
      >
        <main
          style={{
            flex: "1 1 100%",
          }}
        >
          {children}
        </main>
      </div>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
